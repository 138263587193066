import { graphql, Link } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Col, Container } from "react-bootstrap"
import { useFlexSearch } from "react-use-flexsearch"
import FindDreamJob from "../components/careers/finddreamjob"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./search.scss"

/* Change the flexsearch results to fit graphql result */
const unFlattenResults = results =>
  results.map(post => {
    const { slug, keywords, title, excerpt } = post
    return { excerpt, frontmatter: { slug, title, keywords } }
  })

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "search"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    localSearchPages {
      index
      store
    }
  }
`

const SearchPage = ({
  data: {
    localSearchPages: { index, store },
    //allMarkdownRemark: { nodes },
  },
  location,
}) => {
  const { i18n } = useI18next()
  const { t } = useTranslation("search")
  const pageTitle = t("Website Search")
  const pageDescription = t("Search in our website")
  const lang = i18n.resolvedLanguage.toLocaleUpperCase()
  const meta = []

  const breadcrumbMap = [{ title: t("Search"), url: "#" }]

  /** Getting search params from url */
  const params = new URLSearchParams(location.search)
  const query = params.get("s") || ""
  const results = useFlexSearch(query.toLowerCase(), index, store, {
    bool: "and",
  })
  const posts = unFlattenResults(results)

  const resultsList = posts.map(({ frontmatter, excerpt }, index) => {
    const querySplit = query.split(" ")

    const indexSearch = excerpt
      .toLowerCase()
      .indexOf(querySplit[0].toLowerCase())
    const lenght = 300
    let start = 0
    let end = lenght
    if (indexSearch > lenght) {
      start = indexSearch - lenght / 2
      end = indexSearch + lenght / 2
    }

    let displayText =
      (start > 0 ? "..." : "") + excerpt.slice(start, end) + "..."

    querySplit.forEach(split => {
      var regEx = new RegExp(split, "ig")
      displayText = displayText.replaceAll(regEx, "<span>" + split + "</span>")
    })

    return (
      <li className="row" key={index}>
        <Col className="li-wrapper">
          <Link to={frontmatter.slug}>
            <h3>{frontmatter.title}</h3>
          </Link>
          <p dangerouslySetInnerHTML={{ __html: displayText }}></p>
        </Col>
        <hr></hr>
      </li>
    )
  })

  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
        <Seo
          title={pageTitle}
          description={pageDescription}
          lang={lang}
          meta={meta}
        />
        <div className="max-container-pages">
          <section className="section-search">
            <Container className="container-search" fluid="xl">
              <h1>{t("Search")}</h1>
              <h2>{'"' + query + '"'}</h2>
              <ul>{resultsList}</ul>
              {(posts && posts.length > 0) || (
                <div className="row">
                  <Col className="empty-search">
                    <h3>{t("Sorry, We couldn't find any pages for your search")}</h3>
                  </Col>
                  <hr></hr>
                </div>
              )}
            </Container>
          </section>
          <FindDreamJob></FindDreamJob>
        </div>
      </Layout>
    </>
  )
}

export default SearchPage
